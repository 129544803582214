import React from "react"
import Layout from "../components/layout"
import Seo from "../components/Seo"
import Head from "../components/Head"
import styled from "styled-components"
import breakpoints from "../components/GlobalStyle/globals/breakpoints"

const E = {
  Content: styled.div`
    overflow: hidden;
    z-index: 2;
    background: #fff;
    box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.06);
    border-radius: 40px 5px 40px 40px;
    padding: 30px;
    margin: 100px 0;

    h3 {
      padding: 10px 0;
      @media (max-width: ${breakpoints.large}px) {
        text-align: center;
      }
    }
    p {
      padding: 10px 0;

      width: 100%;
    }

    h2 {
      font-size: 4rem;
      @media (max-width: ${breakpoints.medium}px) {
        font-size: 3rem;
      }
    }
  `,
}

const DiscPage = () => (
  <Layout>
    <Seo title="אמנת שירות" />
    <Head title="אמנת שירות" />

    <section>
      <div className="container">
        <E.Content>
          <p>
            פולאריס סוכנות לביטוח פנסיוני (2018) בע"מ (להלן: "הסוכנות") –
            מתחייבת לפעול על פי התפישה הממקמת את הלקוח במרכז. זאת, לטובת כל
            לקוחותינו, בהתאם לאמנת שירות הלקוחות ובהתאם לחזון וערכי היסוד של
            הסוכנות.
          </p>
          <h3>הגינות וכיבוד הפרטיות</h3>
          <p>
            הלקוח זכאי לפרטיות ועל כן אנו מתחייבים לשמור על סודיות השיחות עם ועל
            רשומותיו בהתאם להוראות הדין. פעילותינו עבור לקוחותינו נעשית באופן
            הגון ומכבד המתייחס לזמנו של הלקוח בהתאם.
          </p>
          <h3>זמינות ויעילות במתן מענה אישי לכל לקוח</h3>
          <p>
            אנו מעמידים לרשות הלקוח, מגוון אמצעי התקשרות עמנו; דואר אלקטרוני,
            אתר אינטרנט, פקס, דואר ומענה טלפוני אנושי ואישי.
            <br />
            <br />
            <u>שעות קבלת קהל ומועדי שירות לקוחות בסוכנות הם: </u>
            <br />
            <br />
            דרך השלום 5 בניין TOHA קומה 13, תל אביב
            <br />
            שעות קבלת קהל בתיאום מראש
            <br />
            בימים: א'-ה'
            <br />
            בשעות: 08:00-18:00
            <br />
            יום ו' סגור
            <br />
            <br />
            <u>דרכים ליצירת קשר:</u>
            <br />
            טלפון: 052-5556227
            <br />
            דוא"ל: service@polaris-fin.co.il
            <br />
            אתר אינטרנט: www.polaris-fin.co.il
          </p>
          <h3>אנחנו כאן בשביל הלקוח</h3>
          <p>
            הסוכנות תיתן מענה מתאים לצרכי הלקוח, ובתוך שבעה ימי עסקים ממועד
            פניית לקוח בבקשה לתיאום פגישה איתו או בבקשה לקבלת מסמכים.
            <br />
            הסוכנות תקיים תקשורת יעילה וזמינה עם לקוחותיה, תספק מענה ראשוני לכל
            פנייה ותמסור תשובות ברורות ומנומקות ככל הניתן, וזאת תוך שני ימי
            עסקים מקבלת פניית הלקוח.
            <br />
            הסוכנות תיתן ממענה ענייני תוך זמן סביר לבקשת לפניית לקוח בנושא
            תביעה, וזאת מבלי לגרוע מהוראות כל דין לעניין זה. כמו כן, הסוכנות,
            תמסור ללקוח שפנה אליה בעניין תביעה, מידע אודות זכויותיו, ואת דרכי
            הפעולה העומדות בפניו בהליך יישוב התביעה.
          </p>
          <h3>שקיפות ופשטות</h3>
          <p>
            אנו שואפים לייצר חוויה קלה ונעימה ללקוח. לדבר עם הלקוח בשפה ברורה
            ומובנת, לשקף ולפשט את השלבים הנדרשים לביצוע בקשותיו וללוות אותו
            בבירוקרטיה הנדרש, מול חברות הביטוח.
          </p>
          <h3>מקצועיות</h3>
          <p>
            הביטוח הינו אחד מאמצעי הפחתת הסיכון והבטחת העתיד החשובים והמשמעותיים
            של הלקוח. אנו מבטיחים להעמיד לרשות הלקוח את טובי העובדים, המומחים
            והוותיקים בתחום, אשר עוברים הכשרות מקצועיות ככל הנדרש. בכל פניה של
            הלקוח אלינו אנו מתחייבים לתת מענה מתאים לצרכיו לפי העדפותיו האישיות
            של כל לקוח.
          </p>
        </E.Content>
      </div>
    </section>
  </Layout>
)

export default DiscPage
